  .modal {
    background: rgba(0, 0, 0, 0.6);
  }
  .modal-container {
    position: absolute;
    width: 60%;
    background: #ffffff;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .hederModel{
      text-align: end;
  }

  
.modelContainer {
  .ant-modal-header{
      background-color: #1c77bb;
      
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
      padding: 10px 24px;
      text-align: center;
  }
  .ant-modal-title{
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      padding: 5px;
  }
  .ant-modal-content {
      border-radius: 5px !important;
  }
  .ant-modal-close-x{
      width: 42px;
      height: 42px;
      line-height: 40px;
  }
  .ant-modal-close-icon{
      padding: 2px;
      background-color: #F94144;
      border-radius: 15px;
      font-size: 9px;
  }
  
}
.ant-modal-header .ant-modal-title {
  font-weight: var(--border-color-2);
  color: var(--border-color-5);
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}

  @media (max-width: 443px) {
    .modal-container {
      position: absolute;
      width: 100% !important;
      background: #ffffff;
      right: 0% !important;
      bottom: 0% !important;
      padding:  \15px !important;
      /* height: 700px !important; */
      overflow: auto;
  }
}