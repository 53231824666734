.toast-content{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 30%;
    /* border: 1px solid; */
}
.toasted{
    /* position: fixed;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background: white;
  width: 400px;
  font-size: 16px;
  transition: all .5s;
  /* z-index: 9999; */
  /* box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2); */ 
}