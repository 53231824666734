.service_pay_parent,.service_pay2_parent{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding:20px;
    >div{
        text-transform: none;
        display: grid;
        padding-left: 15px;
        >label:nth-child(1){
            font-weight: 600;
            font-size: 18px;
        }
    }
  }
  .listing_services{
      >div img{
          width:100%;
          height:200px;
          object-fit: cover;
      }
  }
  .amt_ch{
    font-size: 15px;
    font-weight: 500;
      >del{
          padding-right: 10px;
      }
  }
  .btn_add{
      background-color: #8ab74e;
      color:#fff;
      padding: 3px 15px;
      border-radius: 3px;
  }
  .btn_remove{
    background-color: #ff4d4f;
    color:#fff;
    padding: 3px 15px;
    border-radius: 3px;
  }
  .service_con{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
  }
  .cont_btn{
    text-align: end;
    margin-top: 30px;
    >button{
        background-color: #009587;
        padding:10px 20px;
        border-radius: 2px;
        color:#fff;
        font-weight: 500;
    }
  }

  .checkout_div{
      display: flex;
      justify-content: space-between;
      >div{
          display: grid;
          >label:nth-child(2){
              font-weight: 600;
          }
          >label:nth-child(3){
              color:#009587;
              font-weight: 500;
          }
      }
  }

  .moreitems{
      color:#009587;
      font-weight: bold;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px 20px;
      margin-bottom: 15px;
  }

  .payment_detal{
      >div{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          font-weight: 400;
          padding:0px 20px;
          font-size: 16px;
          >label:nth-child(2){
              font-weight: 500;
          }
      }
      >div:nth-child(3){
        >label{
            font-weight: 600;
            color: orange;
        }
      }
      >div:nth-child(4){
        >label{
            font-weight: 500;
            // color: orange;
            font-size: 18px;
        }
      }
  }

  .discount_div{
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
      >div{
      position: relative;
      >img{
        width: 20px !important;
        height: 20px !important;
        margin-top: 2px;
        position: absolute;
        left: -11px;
        z-index: 1;
      }
      >span:nth-child(2){
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 8px solid #fff;
        position: absolute;
        top: 2px;
        left: 6px;
      }
      >span:nth-child(3){
        font-size: 12px;
        background: #009587;
        padding: 2px 8px 2px 11px;
        color: #fff;
        border-radius: 0 8px 8px 0;
        margin-left: 6px;
      }
    }
  }
.ltn__service-details-area{
.ant-modal-body{
      overflow-y: scroll;
  height: 556px;
}
}
.btn_items{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  >div{
    display: grid;
    font-weight: 500;
    font-size: 15px;
  }
}


@media screen and (min-device-width: 320px) and (max-device-width: 380px) { 
  .service_pay_parent{
    display: block;
    >img{
      width:100% !important;
      // object-fit: cover;
    }
  }
  .checkout_div{
    display: block;
    >div{margin-bottom: 10px;}
  }
}