.ant-select{
  border: 2px solid;
  border-color: var(--border-color-1);
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  /* height: 65px; */
  line-height: 60px;
  width: 100%;
  border: solid 1px #e8e8e8;
  /* margin-bottom: 30px !important; */
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-focused{
  border: 1px solid red;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border-color:transparent !important;
  box-shadow:none !important;
  height: 47px !important;
  padding: 10px 0px 0px 20px !important;
  text-align: start !important;

}

.pos_prefix{
position: absolute;
top: 0;
z-index: 3443565;
left: 11px;
top: 32%;
}
.sel_parent{
position: relative;
margin-bottom: 20px;
}
.sel_icon{
color: #ec4249 !important;
font-size: 16px !important;
}
.ant-select-selection-item{
  color: rgb(0 0 0 / 45%);
  font-weight: 700;
  font-size: 15px;
}
.Errormsg{
color: red;
font-size: 13px;
margin-top: 5px;
}